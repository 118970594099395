<template>
  <div class="mobile-container">
    <mobileStatusBar title="消息通知" />
    <div ref="listContainer" class="list-container" @scroll="handleScroll">
      <!-- <van-list  v-model="loading" :finished="finish" @load="handleGetGgList" loading-text="加载中..." finished-text="" error-text="加载失败" direction="up"> -->
        <div v-if="finish && !loading && noticeList.length == 0" class="empty-item">
          <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
        </div>
        <div ref="listContent" v-else class="list">
          <div class="item" v-for="(item, index) in noticeList" :key="index">
            <div class="header-item img-cover"><img v-if="noticeInfo.header" :src="noticeInfo.header" /></div>
            <div class="detail">
              <div class="name">{{ noticeInfo.title }} {{ $TOOL.dateFormat(item.AddTime * 1000) }}</div>
              <div class="content">
                <div v-html="item.CnContent"></div>
                <!-- <div v-if="$t('website.lang') == 'en'" v-html="item.EnContent"></div>
                <div v-if="$t('website.lang') == 'tc'" v-html="item.TcContent"></div> -->
              </div>
            </div>
          </div>
        </div>
      <!-- </van-list> -->
    </div>
    <div class="footer-item">
      <div class="content">
        <div class="input-item">全员禁言中</div>
        <div class="icon img-cover"><img src="../../assets/images/face-icon.png" /></div>
        <div class="icon img-cover"><img src="../../assets/images/add-icon.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import mobileStatusBar from '@/components/mobileStatusBar'
 
export default {
  name: "Notice",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      PageCount: 10,
      Page: 1,
      finish: false,
      loading: false,
      noticeList: [],
      noticeInfo: {},
      listContentHeight: 0
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted () {
    this.handleGetSetting()
    this.handleGetGgList()
  },
  methods: {
    handleScroll(e) {
      const top = e.target.scrollTop
      if (top == 0) {
        this.handleGetGgList()
      }
    },
    async handleGetSetting() {
      var res = await this.$API.getSet.post({
        Key: 'sstz'
      })
      const data = JSON.parse(res.info)
      this.noticeInfo = data
    },
    async handleGetGgList() {
      const { PageCount, Page, noticeList, finish, loading, listContentHeight } = this
      if (finish || loading) {
        return
      }
      this.loading = true
      var res = await this.$API.noticeList.post({
        PageCount,
        Page
      })
      this.noticeList = [
        ...res.info,
        ...noticeList,
      ]
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
      this.$nextTick(() => {
        const height = this.$refs.listContent.offsetHeight
        this.listContentHeight = height
        if (Page == 1) {
          this.$refs.listContainer.scrollTop = height
        } else {
          this.$refs.listContainer.scrollTop = height - listContentHeight
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.list-container {
  flex: 1;
  overflow-y: auto;
}
.list {
  overflow: hidden;
  padding: .4rem .32rem 0;
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: .6rem;
    .header-item {
      width: .8rem;
      height: .8rem;
      border-radius: .24rem;
      overflow: hidden;
    }
    .detail {
      flex: 1;
      margin-left: .24rem;
      .name {
        color: #ccc;
        font-size: .24rem;
        line-height: .34rem;
      }
      .content {
        margin-top: .1rem;
        border-radius: 0 .24rem .24rem .24rem;
        background-color: #fff;
        padding: .2rem;
        color: #333;
        font-size: .28rem;
        line-height: .48rem;
        /deep/img {
          max-width: 4rem;
          height: auto !important;
        }
      }
    }
  }
}
.footer-item {
  width: 100%;
  height: 1.44rem;
  .content {
    position: fixed;
    bottom: 0;
    width: var(--max-width);
    left: 50%;
    margin-left: var(---max-width-50);
    background-color: #fff;
    padding: .24rem .32rem .48rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .input-item {
      flex: 1;
      height: .72rem;
      line-height: .72rem;
      background-color: #f8f8f8;
      border-radius: .36rem;
      padding: 0 .32rem;
      font-size: .28rem;
      color: #ccc;
    }
    .icon {
      margin-left: .2rem;
      width: .52rem;
      height: .52rem;
      cursor: pointer;
    }
  }
}
</style>
